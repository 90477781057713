<template>  
  <div>
    <v-card>
      <v-card-title class="text-center justify-center">
        <h3 class="font-weight-bold basil--text">
          {{$i18n.t('Transportador')}}
        </h3>
      </v-card-title>

      <v-card-text>
        <v-row v-if="loading">
          <v-col cols="12" sm="12" align="center">
            <v-progress-circular
              slot="append"
              indeterminate
              color="primary">
            </v-progress-circular>
          </v-col>
        </v-row>

        <v-row v-if="!loading">
          <v-col cols="12" sm="12" align="right">

            <v-btn
              @click="removendo = true"
              color="red"
              dark
              v-if="empresa.id">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>

            <v-btn
              @click="lista">
              <v-icon>
                mdi-view-headline
              </v-icon>
            </v-btn>

          </v-col>
        </v-row>

        <v-form
          ref="form"
          v-model="valido"
          v-if="!loading"
          lazy-validation>

          <v-row>
            <v-col cols="12">
              <v-text-field
                ref="nome"
                v-model="empresa.nome"
                :rules="requiredRules"
                :label="$i18n.t('Nome')"
                required>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <img width="100px" :src="empresa.logo">
              <file-upload
                ref="file"
                class="button-upload"
                @upload="upload">
              </file-upload>
            </v-col>
          </v-row>

        </v-form>

        <div class="w100" v-if="!loading">
          <v-row>
            <v-col align="center" cols="12">
              <v-btn
                color="success"
                elevation="2"
                @click="salva"
                :disabled="!validateForm">
                <v-icon>
                  mdi-check
                </v-icon>
                {{$i18n.t('Salvar')}}
              </v-btn>
            </v-col>
          </v-row>
        </div>

      </v-card-text>
    </v-card>

    <v-dialog
      v-model="removendo"
      persistent
      max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{$i18n.t('RemoveTransportador')}}
        </v-card-title>
        <v-card-text>{{$i18n.t('RemoveTransportadorConfirmacao')}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="removendo = false">
            {{$i18n.t('Nao')}}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="remove">
            {{$i18n.t('Sim')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileUpload from '../../components/FileUpload.vue';
  export default {
    components: { FileUpload },
    data: () => ({
      id: null,
      loading: false,
      empresa: {},
      valido: false,
      requiredRules: [
        v => !!v
      ],
      removendo: false
    }),
    computed: {
      validateForm() {
        return this.empresa
          && this.empresa.nome != '';
      }
    },
    methods: {
      load() {
        this.loading = true;
        this.$http.get(`/api/ide/empresas/${this.id}`)
            .then((req) => {
              if (req.data.sucesso) {
                this.empresa = req.data.objeto;
                if (this.empresa == null)
                  this.$router.push('/error-403');
              }
              else
                 this.$msg.erro(req.data.mensagem);
            })
            .catch((e) => {
              this.$msg.erro(e.response.data.message);
            })
            .finally(() => {
              this.loading = false;
            });
      },
      lista() {
        this.$router.push('/transportadoras');
      },
      remove() {
        this.removendo = false;
        this.loading = true;
        this.$http.delete(`/api/ide/empresas/${this.id}`)
          .then((req) => {
            if (req.data.sucesso) {
              this.empresa = {};
              this.$msg.sucesso(this.$i18n.t('Sucesso'));
            } else
              this.$msg.erro(req.data.mensagem);
          })
          .catch((e) => {
            this.$msg.erro(e.response.data.message);
          })
          .finally(() => {
            this.loading = false;
            this.lista();
          });
      },
      salva() {
        
        if (this.$refs.form.validate()) {
          this.loading = true;

          let data = {
            "id": this.empresa.id,
            "nome": this.empresa.nome
          };

          this.$http.post('/api/ide/empresas', data)
              .then((req) => {
                if (req.data.sucesso) {
                  this.empresa = req.data.objeto;
                  this.$msg.sucesso(this.$i18n.t('Sucesso'));
                } else
                  this.$msg.erro(req.data.mensagem);
              })
              .catch((e) => {
                this.$msg.erro(e.response.data.message);
              })
              .finally(() => {
                this.loading = false;
                this.focus();
              });
        } else {
          this.focus();
        }
      },
      focus() {
        const self = this;
        setTimeout(function() {
          self.$refs.nome.focus();
        }, 200);
      },
      upload(form) {
        this.$http.post(`/api/ide/empresas/logo/${this.empresa.id}`, form)
          .then((res) => {
            this.empresa.logo = res.data;
          });
      }
    },
    mounted() {
      this.id = this.$route.params.id;
      if (this.id != null) {
        if (this.id === 'add')
          this.empresa = {};
        else
          this.load();
      }
      this.focus();
    }
  }
</script>